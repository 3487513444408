import {GraphQLErrorExtensions} from 'graphql/error';

export enum GraphqlErrorCode {
  Unauthenticated = 'UNAUTHENTICATED',
  ServerError = 'INTERNAL_SERVER_ERROR',
  Forbidden = 'FORBIDDEN',
  BadUserInput = 'BAD_USER_INPUT',
  Validation = 'VALIDATION',
  Maintenance = 'MAINTENANCE',
}

export interface BackendValidationErrorExtensions extends GraphQLErrorExtensions {
  code: GraphqlErrorCode.Validation;
  errors: BackendValidationErrors;
}

export interface BackendValidationErrors {
  [key: string]: string[] | BackendValidationErrors[];
}
